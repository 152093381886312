import React, { useEffect } from 'react'
import AOS from 'aos'
import { FACEBOOK_URL, FACEBOOK_MESSENGER_URL, INSTAGRAM_URL, WHATSAPP_URL } from '../../static/constants/Config'

const SimpleGrid = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, [])

    const styleDivs = {
        color: "black"
    }

    return (

        <div className="bg_gray">
            <section className="container nuestras_redes_section">
                <div className="row" data-aos="fade-right">
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <h2 className="card-title">ENCUENTRANOS EN NUESTRAS REDES</h2>
                    </div>
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <span className="card-img">
                            <a style={styleDivs} href={FACEBOOK_URL} rel="noreferrer" target="_blank"><i
                                className="fab fa-facebook-f fa-5x"></i></a>
                        </span>
                    </div>
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <span className="card-img">
                            <a style={styleDivs} href={INSTAGRAM_URL} rel="noreferrer" target="_blank"><i
                                className="fab fa-instagram fa-5x"></i></a>
                        </span>
                    </div>
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <span className="card-img">
                            <a style={styleDivs} href={WHATSAPP_URL} rel="noreferrer" target="_blank"><i
                                className="fab fa-whatsapp fa-5x whats_app_icon"></i></a>
                        </span>
                    </div>
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <span className="card-img">
                            <a style={styleDivs} href={FACEBOOK_MESSENGER_URL} rel="noreferrer" target="_blank"><i
                                className="fab fa-facebook-messenger fa-5x "></i></a>
                        </span>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SimpleGrid