import React, { useEffect } from 'react'
import AOS from 'aos'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

const ThreeCards = () => {


    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const imageMap = {
        "Nuestra historia": require('../../static/images/nosotros/backgroundCard.jpg'),
    }


    return (
        <section className="container" id="descriptionSection">
            <Container>
                <Row>
                    <Col data-aos="flip-up">
                        <Card>
                            <Card.Body>
                                <Card.Img variant="top" src={imageMap["Nuestra historia"]}
                                    alt="Las mejores marcas" />
                                <Card.Title>Historia</Card.Title>
                                <Card.Text>
                                    Desde nuestro inicio el 1 de mayo de 2023, hemos sido un equipo multidisciplinario comprometido con brindar atención personalizada y de alta calidad.
                                    Nos especializamos en diversas áreas de la ingeniería, adaptando nuestras soluciones a las necesidades específicas de cada cliente.
                                    Nos gusta trabajar en equipo y nos esforzamos por ser un referente en el mercado de la tecnología.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col data-aos="flip-up">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Misión <i className="fa-solid fa-landmark"></i>
                                </Card.Title>
                                <Card.Text>
                                    Nuestra misión es ofrecer servicios confiables y eficientes que superen las expectativas.
                                    Facilitar el acceso a la tecnología a un precio justo, proporcionando soluciones sencillas y
                                    efectivas para aquellos que no están familiarizados con dispositivos como computadoras, multifuncionales y celulares.
                                    Nos comprometemos a minimizar las complicaciones y hacer que la tecnología sea mas accesible para todos.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Visión <i className="fa-solid fa-eye-low-vision"></i>
                                </Card.Title>
                                <Card.Text>
                                    Ser la empresa líder en soluciones tecnológicas accesibles y fáciles de usar,
                                    reconocida por nuestra dedicación a la calidad, la innovación y el servicio al cliente.
                                    Aspiramos a crear un mundo donde la tecnología esté al alcance de todos, sin barreras ni complicaciones.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col data-aos="flip-up">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Valores <i className="fa-brands fa-slideshare"></i>
                                </Card.Title>
                                <Card.Text>
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <b>1.Transparencia:</b> Nos comprometemos a ser claros y honestos en todas nuestras interacciones, asegurando que nuestros clientes siempre sepan exactamente lo que están obteniendo.
                                        </li>
                                        <li className="list-group-item">
                                            <b>2.Adaptabilidad:</b> Ofrecemos soluciones personalizadas que se ajustan a las necesidades específicas de cada cliente, garantizando que reciban exactamente lo que necesitan.
                                        </li>
                                        <li className="list-group-item">
                                            <b>3.Calidad:</b> Mantenemos los más altos estándares en todos nuestros productos y servicios, asegurando que cada solución que ofrecemos sea de la mejor calidad posible.
                                        </li>
                                        <li className="list-group-item">
                                            <b>4.Buen Servicio:</b> Nos esforzamos por proporcionar una atención al cliente excepcional, escuchando y respondiendo a las necesidades y preocupaciones de nuestros clientes de manera rápida y eficiente.
                                        </li>
                                        <li className="list-group-item">
                                            <b>5.Honradez:</b> Actuamos con integridad en todas nuestras operaciones, manteniendo un comportamiento ético y responsable en todo momento
                                        </li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ThreeCards