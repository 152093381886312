import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const CardShow = () => {

    const imageMap = {
        "Vigilando todo el tiempo": require('../../static/images/servicios/camaras_1.jpg'),
        "Mejora el rendimiento": require('../../static/images/servicios/mantenimiento.jpg'),
        "Crece tu negocio": require('../../static/images/servicios/venta.jpg'),
        "Se visible para todos": require('../../static/images/servicios/web.jpg'),
    }

    return (
        <section className="container">
            <Row xs={1} md={2} className="g-4" id="descriptionSection">
                <Col key={1}>
                    <Card data-aos="fade-right">
                        <Card.Img variant="top" src={imageMap["Vigilando todo el tiempo"]}
                            alt="Vigilando todo el tiempo" />
                        <Card.Body>
                            <Card.Title>Servicio Completo</Card.Title>
                            <Card.Text>
                                Compra con nosotros y ten un <b>descuento</b> en la instalacion de las camaras.
                                La instalacion incluye la configuracion de las camaras y la app para que puedas verlas desde tu celular.
                                Tenemos diferentes paquetes para que elijas el que mas te convenga, desde 2 camaras hasta 8 camaras para <b>casa o negocio</b>.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={2}>
                    <Card data-aos="fade-left">
                        <Card.Img variant="top" src={imageMap["Mejora el rendimiento"]}
                            alt="Mejora el rendimiento" />
                        <Card.Body>
                            <Card.Title>Diagnostico, reparacion y optimizacion</Card.Title>
                            <Card.Text>
                                Diagnositcamos tu computadora o laptop para saber que le esta fallando.
                                Reparamos el problema que tenga y optimizamos su rendimiento para que trabaje mas rapido.
                                Tambien te damos <b>garantia</b> de 3 meses en la reparacion.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={3}>
                <Card data-aos="fade-right">
                        <Card.Img variant="top" src={imageMap["Crece tu negocio"]}
                            alt="Crece tu negocio" />
                        <Card.Body>
                            <Card.Title>Punto de venta</Card.Title>
                            <Card.Text>
                                Te apoyamos a darle el impulso que necesita tu negocio. Adquiere un punto de venta y lleva un control de tus ventas.
                                Somos distribuidores autorizados y te damos <b>capacitacion</b> para que puedas usarlo, puedes probarlo <b>28 dias</b> para ver si te convence, y al final si decides quedartelo te damos un <b>descuento</b> en la compra.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={4}>
                    <Card data-aos="fade-left">
                        <Card.Img variant="top" src={imageMap["Se visible para todos"]}
                            alt="Se visible para todos" />
                        <Card.Body>
                            <Card.Title>Muestra tu producto</Card.Title>
                            <Card.Text>
                                Ofrecemos desarrollo personalizado en areas de <b>paginas web</b>, <b>E-Commerce</b> y <b>testing</b>.
                                Obten 1 año de host gratis en la compra de tu pagina web, con tu cuenta de correo personalizada.
                                Contactanos para mas informacion.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}

export default CardShow
