const FooterNetwork = ({ urlRedirect, socialNetwork }) => {

    const setIcon = (socialNetwork) => {
        switch (socialNetwork) {
            case "facebook":
                return "fab fa-facebook-f fa-2x"
            case "instagram":
                return "fab fa-instagram fa-2x"
            case "whatsapp":
                return "fab fa-whatsapp fa-2x"
            case "tiktok":
                return "fab fa-tiktok fa-2x"
            case "mipekicha":
                return "fa-solid fa-paw"
            case "messenger":
                return "fab fa-facebook-messenger fa-2x"
            default:
                return "fab fa-facebook-f"
        }
    }

    return (
        <a className="m-1" href={urlRedirect} target="_blank" rel="noopener noreferrer">
            {socialNetwork === "mipekicha" ? "Created By Mipekicha Digital " : null}
            <i className={setIcon(socialNetwork)}></i>
        </a>
    )
}

export default FooterNetwork
