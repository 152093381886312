import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

const trackingId = 'G-G571BJY0PL'
ReactGA.initialize(trackingId)

const Analytics = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return null
}

export default Analytics